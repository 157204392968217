import React, { useRef, useState } from "react";
import styles from "./HomePage.module.css";
import { GlbModel } from "../../components/Models/GlbModel";
import { OrbitControls, ScrollControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import grid from "../../assets/background/grid.png";
import circle from "../../assets/background/circle.png";
import { NavLink } from "react-router-dom";

const HomePage: React.FC = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isRotated, setIsRotated] = useState(true);
  // const [isRotated, setIsRotated] = useState(false);

  const handleRotationComplete = (isComplete: boolean) => {
    setIsRotated(isComplete);
  };

  return (
    <div className="fixed w-screen h-screen top-0 left-0 flex flex-col items-center justify-center bg-slate-950 gap-7 pt-[22vh]">
      {/* <div className="fixed w-screen h-screen top-0 left-0 flex flex-col items-center justify-center bg-black"> */}
      {/* <Canvas camera={{ fov: 6, position: [0, 0, 30] }} style={{ opacity: isRotated ? 0 : 1 }} className='z-[10]'>
                <ambientLight />
                <directionalLight intensity={10} position={[50, 0, 0]} />
                <OrbitControls enableZoom={false} enablePan={false} enableRotate={false} />
                <ScrollControls pages={3} damping={0.25}>
                    <GlbModel contentRef={contentRef} onRotationComplete={handleRotationComplete} />
                </ScrollControls>
            </Canvas > */}
      <img
        src={grid}
        alt="grid"
        className="fixed h-screen w-screen left-0 right-0 top-0 bottom-0 object-cover opacity-20"
      />
      <div
        ref={contentRef}
        className={` bg-slate-950 text-center text-white p-4 mt-16 sm:mt-1`}
        // className={`absolute top-[8vh] sm:top-[30vh] bg-black text-center text-white p-4 mt-16 sm:mt-1`}
        style={appearStyle(1, isRotated)}
      >
        <div className="flex flex-col gap-3 opacity-80 font-main-sans">
          <div className={styles.circleBehind}>
            <h1 className="text-4xl font-extrabold font-logo">RigManic</h1>
            <img
              src={circle}
              alt="circle"
              className="w-5 absolute right-[25%] top-[100%] opacity-80"
            />
            <div className={styles.line1}>
              <div></div>
            </div>
            <div className={styles.line2}>
              <div></div>
            </div>
          </div>
          {/* <h1 className="text-4xl font-extrabold font-logo">RigManic</h1> */}
          <p className="text-3xl  mt-10 font-main-serif">
            Humanoid Robots
          </p>
          {/* <p className="text-base opacity-70">
            - Humanoid Robot Boxing -
          </p> */}
          {/* <a
                        className='mt-[10vh] border p-3 font-bold '
                        href='https://docs.google.com/forms/d/e/1FAIpQLSfZKeCXvwT-NAudtDpBgBJeF5wTkc3TZ1xfHljbWSmrdcxkFA/viewform?embedded=true'
                        target="_blank"
                        onMouseOver={(e) => {
                            e.currentTarget.style.transform = 'translate(-3px, -3px)';
                            e.currentTarget.style.boxShadow = '6px 6px 0px 0px rgb(255,255,255, 0.2)';
                        }}
                        onMouseOut={(e) => {
                            e.currentTarget.style.transform = 'translate(0, 0)';
                            e.currentTarget.style.boxShadow = 'none';
                        }}
                    ><button>Contact Us</button></a> */}
        </div>
      </div>
      <div className="flex flex-col gap-3">

        {/* <NavLink
          className="bg-white w-56 py-3 text-center font-bold text-slate-950 z-[99999999]"
          to="/demo"
          onMouseOver={(e) => {
            e.currentTarget.style.transform = "translate(-3px, -3px)";
            e.currentTarget.style.boxShadow =
              "6px 6px 0px 0px rgb(255,255,255, 0.2)";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.transform = "translate(0, 0)";
            e.currentTarget.style.boxShadow = "none";
          }}
        >
          <p>Demo</p>
        </NavLink> */}
        <a
          className="border py-3 px-16 font-bold text-white z-[99999999]"
          // className='absolute top-[70vh] sm:top-[60vh] border py-3 px-16 font-bold text-white z-[99999999]'
          //href="https://docs.google.com/forms/d/e/1FAIpQLSdL6iO-2O6u1SqXQiviZCFxJfkKx7pu_8awZHhvMwzNTCrmfQ/viewform?embedded=true"
          href="https://docs.google.com/forms/d/e/1FAIpQLSfr4kvwBZEPCkpBLoXIIoaKdy2FNGi8SyzojdFyorhr6OS7tQ/viewform?embedded=true"
          target="_blank"
          rel="noopener noreferrer"
          onMouseOver={(e) => {
            e.currentTarget.style.transform = "translate(-3px, -3px)";
            e.currentTarget.style.boxShadow =
              "6px 6px 0px 0px rgb(255,255,255, 0.2)";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.transform = "translate(0, 0)";
            e.currentTarget.style.boxShadow = "none";
          }}
        // style={appearStyle(1, isRotated)}
        >
          <p>Join Waitlist</p>
        </a>
      </div>

      <p
        className="text-sm p-10 text-white z-[99999999999] mt-auto"
        // <p className='text-sm p-4 text-white z-[99999999999] '
        style={appearStyle(0.3, isRotated)}
      >
        &copy; 2024 RigManic
      </p>
    </div>
  );
};

export default HomePage;

function appearStyle(opacity: number, isRotated: boolean): React.CSSProperties {
  return {
    transition: "opacity 0.5s",
    pointerEvents: isRotated ? "auto" : "none",
    opacity: isRotated ? opacity : 0,
  };
}
