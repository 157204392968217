import React, { useContext, useEffect, useRef, useState } from 'react';
import { generateModel } from '../../api/demo';
import Spinner from '../../components/Spinner/Spinner';
import * as THREE from 'three';
import styles from "./DemoPage.module.css";
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

declare module 'three' {
    export interface WebGLRenderer {
        physicallyCorrectLights: boolean;
    }
}

interface ModelProps {
    modelPath: string;
}

// const Model: React.FC<ModelProps> = () => {
const Model: React.FC<ModelProps> = ({ modelPath }) => {
    // TO DISPLAY
    // const [modelPath, setModelPath] = useState("https://replicate.delivery/pbxt/UvKKgNj9mT7pIVHzwerhcjkp5cMH4FS5emPVghk2qyzMRwUSA/gradio_output.ply");


    // LOCAL STATES
    const [isGenerating, setIsGenerating] = useState(false);
    const [message, setMessage] = useState("");

    // CONTEXT
    // const { setIsDark, isDark } = useContext(GlobalContext);

    const mountRef = useRef<HTMLDivElement>(null);
    const controlsRef = useRef<TransformControls>();

    // const modelPath = "166.fbx";
    const bones: THREE.Bone[] = []; // Define bones array in the higher scope
    const draggableSpheres: THREE.Object3D[] = []; // Add this line to define draggableSpheres

    // const modelPath = "test3.glb";
    // const modelPath = "test.glb";
    // const modelPath = "https://storage.googleapis.com/rigmanic/07616bdf4f68228d2d6a22f4f3d74220.glb";
    useEffect(() => {
        if (!modelPath) return;

        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.01, 10);
        camera.position.set(0, 1, 5);
        camera.lookAt(scene.position);

        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.physicallyCorrectLights = true;
        renderer.toneMapping = THREE.ACESFilmicToneMapping;
        // renderer.setClearColor(0xfab, 1); // Set to white color and full opacity
        renderer.toneMappingExposure = 10;

        mountRef.current?.appendChild(renderer.domElement);

        const light = new THREE.HemisphereLight(0xbbbbff, 0x444422);
        light.position.set(0, 1, 0);
        scene.add(light);

        const orbitControls = new OrbitControls(camera, renderer.domElement);
        orbitControls.enableDamping = true;
        orbitControls.dampingFactor = 0.25;
        orbitControls.screenSpacePanning = false;
        orbitControls.minDistance = 1;
        orbitControls.maxDistance = 10;
        orbitControls.maxPolarAngle = Math.PI / 2;


        const loader = new GLTFLoader();
        loader.load(modelPath, (gltf) => {
            const model = gltf.scene;
            model.scale.set(0.1, 0.1, 0.1);
            model.position.set(1, -0.5, 0);
            // fbx.rotation.x = Math.PI / 2;
            scene.add(model);
            // scene.updateMatrixWorld(true);


            // ADD SPHERE TO BONE //
            // model.traverse((object) => {
            //     if ((object as THREE.Bone).isBone) {
            //         bones.push(object as THREE.Bone);

            //         // Create a red sphere mesh for each bone
            //         const sphereGeometry = new THREE.SphereGeometry(0.04, 16, 16); // Adjust size as needed
            //         const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true });
            //         const sphereMesh = new THREE.Mesh(sphereGeometry, sphereMaterial);
            //         sphereMesh.userData.bone = object;
            //         object.userData.sphere = sphereMesh;
            //         // Ensure the world matrix is updated
            //         object.updateMatrixWorld(true);
            //         sphereMesh.position.setFromMatrixPosition(object.matrixWorld);

            //         // Add the sphere mesh to the scene
            //         scene.add(sphereMesh);
            //         draggableSpheres.push(sphereMesh);

            //     }
            // });


            // TO HIDE TRANSFORM FUNCTION //
            /*
                        const transformControls = new TransformControls(camera, renderer.domElement);
                        controlsRef.current = transformControls;
                        controlsRef.current.visible = true; // Make sure the gizmo is visible
                        scene.add(controlsRef.current);
            
                        transformControls.addEventListener('objectChange', () => {
                            if (controlsRef.current) {
                                const selectedObject = controlsRef.current.object;
            
                                // Check if the selected object is a bone with associated sphere data
                                if (selectedObject?.userData?.sphere) {
                                    const sphere = selectedObject.userData.sphere;
                                    const bone = selectedObject;
            
                                    // Update the sphere's position to match the bone's new world position
                                    sphere.position.setFromMatrixPosition(bone.matrixWorld);
            
                                    // If the bone has children, their world matrices need to be updated as well
                                    if (bone.children.length) {
                                        bone.children.forEach((childBone) => {
                                            if (childBone.userData?.sphere) {
                                                const childSphere = childBone.userData.sphere;
                                                childSphere.position.setFromMatrixPosition(childBone.matrixWorld);
                                            }
                                        });
                                    }
            
                                    renderer.render(scene, camera);
                                }
                            }
                        });
            
            
                        transformControls.addEventListener('dragging-changed', (event) => {
                            orbitControls.enabled = !event.value;
                        });
            
                        */

        }, undefined, (error) => {
            console.error('An error happened', error);
        });


        function animate() {
            requestAnimationFrame(animate); // THIS LINE!!!
            orbitControls.update();
            renderer.render(scene, camera);
        }

        animate();

        function onWindowResize() {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
        }

        window.addEventListener('resize', onWindowResize, false);

        function onMouseClick(event: MouseEvent) {
            const mouse = new THREE.Vector2(
                (event.clientX / window.innerWidth) * 2 - 1,
                -(event.clientY / window.innerHeight) * 2 + 1
            );

            const raycaster = new THREE.Raycaster();
            raycaster.setFromCamera(mouse, camera);
            const intersects = raycaster.intersectObjects(draggableSpheres);
            if (intersects.length > 0) {
                const selectedSphere = intersects[0].object;
                const selectedBone = selectedSphere.userData.bone;

                if (controlsRef.current && selectedBone) {
                    controlsRef.current.attach(selectedBone);
                    controlsRef.current.setMode("translate"); // Assuming you want to translate the bones
                }

                renderer.render(scene, camera);
            }
        }

        window.addEventListener('click', onMouseClick, false);

        return () => {
            window.removeEventListener('resize', onWindowResize);
            window.removeEventListener('click', onMouseClick);
            if (controlsRef.current) {
                controlsRef.current.removeEventListener('objectChange', () => {
                    renderer.render(scene, camera);
                });
                controlsRef.current.removeEventListener('dragging-changed', (event) => {
                    orbitControls.enabled = !event.value;
                });
                scene.remove(controlsRef.current);
            }
            mountRef.current?.removeChild(renderer.domElement);
            scene.clear();
            renderer.dispose();
        };
    }, [modelPath]);


    return <div ref={mountRef} />;
};

export default Model;


