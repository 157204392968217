import { API_ENDPOINT } from ".";


export const getTxt = async (objFile: File) => {
    const formData = new FormData();
    formData.append("file", objFile);

    try {
        const response = await fetch(`${API_ENDPOINT}/generate-rig`, {
            method: "POST",
            credentials: "include",
            body: formData,
        });
        if (!response.ok) {
            throw new Error('Failed to fetch txt file');
        }
        return await response.blob();
    } catch (err) {
        console.error('Error fetching txt file:', err);
        throw err;
    }
};

export const getGlb = async (objFile: File, txtFile: File) => {
    const formData = new FormData();
    formData.append("obj_file", objFile);
    formData.append("txt_file", txtFile);

    try {
        const response = await fetch(`${API_ENDPOINT}/convert-obj-to-glb/`, {
            method: "POST",
            credentials: "include",
            body: formData,
        });
        if (!response.ok) {
            throw new Error('Failed to fetch GLB file');
        }
        const data = await response.json();
        return data.public_url;
    } catch (err) {
        console.error('Error fetching GLB file:', err);
        throw err;
    }
};

export const generateModel = async ({ prompt, imgUrl }: { prompt: string, imgUrl: string; }) => {
    try {
        const response = await fetch(`${API_ENDPOINT}/process/`, {
            method: "POST",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                seed: 6,
                prompt,
                input_image: imgUrl,
                negative_prompt: "ugly, blurry, pixelated obscure, unnatural colors, poor lighting, dull, unclear, cropped, lowres, low quality, artifacts, duplicate"
            }),
        });
        return response;
    } catch (err) {
        console.log(err);
    }
};
export const generateModelLight = async ({ imgUrl }: { imgUrl: string; }) => {
    try {
        const response = await fetch(`${API_ENDPOINT}/process_light/`, {
            method: "POST",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                image_url: imgUrl,
            }),
        });
        return response;
    } catch (err) {
        console.log(err);
    }
};
