import { useState } from "react";
import { useLocation } from "react-router-dom";
import Model from "./Model-glb-no-transformControl";
import { getGlb, getTxt } from "../../api/demo";
import Spinner from "../../components/Spinner/Spinner";

export default function DemoPage() {
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [returnedFBXModelPath, setReturnedFBXModelPath] = useState<string>("");
    // const [status, setStatus] = useState("success");
    const [status, setStatus] = useState("idle");

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const glbParam = queryParams.get('glb');

    const acceptFileType = glbParam === 'true' ? '.glb' : '.obj';


    async function handleGetFBXFile() {
        setStatus("loading");

        try {

            if (uploadedFile) {
                const txtBlob = await getTxt(uploadedFile);
                const txtFile = new File([txtBlob], "temp.txt", { type: txtBlob.type });

                if (txtBlob.size > 0) {
                    const modelPath = await getGlb(uploadedFile, txtFile);
                    setReturnedFBXModelPath(modelPath);
                    setStatus("success");
                }

            }
        } catch (error) {
            setStatus("failed");
            console.error(error);
        }
    }

    function handleDownloadFile() {
        const link = document.createElement('a');
        link.href = returnedFBXModelPath;
        link.download = 'model.glb'; // You can dynamically set the filename if needed
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length === 1) {
            const file = event.target.files[0];
            if (file.name.endsWith(acceptFileType)) {
                setUploadedFile(file);
                if (acceptFileType === '.glb') {
                    setReturnedFBXModelPath(URL.createObjectURL(file));
                    setStatus("success");
                }
            } else {
                alert(`Only ${acceptFileType} files are allowed`);
                event.target.value = ''; // Clear the selected file
            }
        } else {
            alert(`Please select only one ${acceptFileType} file`);
            event.target.value = ''; // Clear the selected files
        }
    };

    return (
        <div className="bg-slate-950 text-slate-200">
            {status === "idle" || status === "loading" ?
                <div className="w-screen h-screen flex justify-center">
                    <div className="flex flex-col gap-2 m-auto">
                        <p className="">{uploadedFile ? `Selected File: ` : "Please upload one .obj file."}<span className="font-bold">{uploadedFile && uploadedFile.name}</span></p>
                        <input
                            type="file"
                            accept={acceptFileType}
                            className={`file-input file-input-ghost w-full max-w-xs border-slate-500 bg-slate-950 text-slate-500 ${uploadedFile && "text-slate-300"}`}
                            onChange={handleFileChange}
                        // onChange={(event) => {
                        //     if (event.target.files && event.target.files.length === 1) {
                        //         const file = event.target.files[0];
                        //         if (file.name.endsWith('.obj')) {
                        //             setUploadedFile(file);
                        //         } else {
                        //             alert('Only .obj files are allowed');
                        //             event.target.value = ''; // Clear the selected file
                        //         }
                        //     } else {
                        //         alert('Please select only one .obj file');
                        //         event.target.value = ''; // Clear the selected files
                        //     }
                        // }}
                        />
                        <button
                            className={`btn border-slate-500 text-slate-950 bg-slate-200 opacity-90 hover:opacity-100 disabled:opacity-30 disabled:bg-slate-200 disabled:text-slate-400`}
                            type="button"
                            onClick={handleGetFBXFile}
                            disabled={!uploadedFile}
                        >
                            {status == "loading" ? <div className="flex items-center"><p>Generating</p><Spinner /></div> : "Generate"}
                        </button>
                    </div>
                </div>

                :
                (
                    status === "success" ?
                        <div>
                            <Model modelPath={returnedFBXModelPath || ""} />
                            <div className="flex flex-col gap-1 items-center fixed bottom-10 left-0 w-screen opacity-80">
                                <button className="btn btn-sm w-[30%]" type="button" onClick={handleDownloadFile}>Download .GLB</button>
                                <button className="btn btn-sm w-[30%] btn-outline border-gray-300 text-gray-300" type="button" onClick={() => { setStatus("idle"); setUploadedFile(null); }}>Back</button>
                            </div>
                        </div>

                        :
                        (status === "failed" &&
                            <div className="bg-slate-950 w-screen h-screen">
                                <div className="flex flex-col gap-5 items-center fixed bottom-[50vh] left-0 w-screen opacity-80 translate-y-[-50%] ">
                                    <p>Sorry, something went wrong, please try again.</p>
                                    <button className="btn btn-sm w-[30%]" type="button"
                                        onClick={() => { setStatus("idle"); }}>Retry</button>
                                </div>
                            </div>
                        )
                )
            }


        </div>
    );
}