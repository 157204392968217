import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCfLpLepX503JGxJJRW6Ym8TD4Yl6UiL4k",
  authDomain: "rigmanic-2024.firebaseapp.com",
  projectId: "rigmanic-2024",
  storageBucket: "rigmanic-2024.appspot.com",
  messagingSenderId: "805450813497",
  appId: "1:805450813497:web:55b29ac6e09fd28faa3de8",
  measurementId: "G-D7ZN003LFW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
