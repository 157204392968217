import React, { useContext, useEffect } from 'react';
import styles from "./Header.module.css";
import { NavLink, useLocation } from 'react-router-dom';
import { GlobalContext } from '../../context/globalContext';

const Header: React.FC = () => {
    const { pathname } = useLocation();
    if (pathname === '/') {
        return null;
    }
    return (
        <header className={`${styles.headerContainer} fixed top-0 left-0 font-main-sans text-xl text-white font-semibold opacity-70 z-[999999] w-full px-6 pt-6 flex justify-between`}>
            <NavLink to="/" className='font-logo font-extrabold'>RigManic</NavLink>
            <a
                className="border py-2 px-8  text-white z-[99999999] font-logo font-bold text-sm"
                // className='absolute top-[70vh] sm:top-[60vh] border py-3 px-16 font-bold text-white z-[99999999]'
                href="https://docs.google.com/forms/d/e/1FAIpQLSdL6iO-2O6u1SqXQiviZCFxJfkKx7pu_8awZHhvMwzNTCrmfQ/viewform?embedded=true"
                target="_blank"
                rel="noopener noreferrer"
                onMouseOver={(e) => {
                    e.currentTarget.style.transform = "translate(-3px, -3px)";
                    e.currentTarget.style.boxShadow =
                        "6px 6px 0px 0px rgb(255,255,255, 0.2)";
                }}
                onMouseOut={(e) => {
                    e.currentTarget.style.transform = "translate(0, 0)";
                    e.currentTarget.style.boxShadow = "none";
                }}
            // style={appearStyle(1, isRotated)}
            >
                <p>Join Waitlist</p>
            </a>
            {/* <NavLink to="/contact-us" className='font-logo font-light hover:underline'>Contact Us</NavLink> */}
        </header>
    );
};

export default Header;
