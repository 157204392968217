import React from 'react';
import styles from "./Footer.module.css";

const Footer: React.FC = () => {
    return (
        <footer className={`${styles.footerContainer} my-auto absolute bottom-0`}>
            {/* <p className='text-sm p-4 text-white z-[99999999999]'>&copy; 2024 IXIM</p> */}
        </footer>
    );
};

export default Footer;
